export const getTitle = (pathname: string) => {
  switch (pathname) {
    case "/": {
      return "Chaindentity | Verified, Decentralized Digital Identity";
    }
    case "/contact": {
      return "Chaindentity | Contact"
    }
    case "/policy/cookie": {
      return "Chaindentity | Our Cookie Policy"
    }
    case "/policy/websitePrivacy": {
      return "Chaindentity | Our Website Policy"
    }
    case "/policy/privacy": {
      return "Chaindentity | Our Privacy Policy"
    }
    case "/404": {
      return "Chaindentity | Page not found"
    }
    default: {
      return "Chaindentity | Anonymous, on-chain, verified digital identities.";
    }
  }
};
