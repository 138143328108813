import * as Three from 'three';

import { IHoverItem } from '../../utils/useGetAttribule';

type TObj = Three.Points<
  Three.BufferGeometry,
  Three.Material | Three.Material[]
> | null;

interface IProps {
  obj: TObj;
  raycaster: Three.Raycaster;
  width: number;
  camera: Three.Camera;
  count: number;
  threshold: number;
  isTransition: boolean;
}

const mousePosition = new Three.Vector2();

window.addEventListener("mousemove", (e) => {
  mousePosition.setX((e.clientX / window.innerWidth) * 2 - 1);
  mousePosition.setY((e.clientY / window.innerHeight) * -2 + 1);
});

let response: number[] = [];
let hoverItemLast: IHoverItem[] = [];

const setHoverItem = (
  hoverItem: IHoverItem[],
  obj: TObj,
  count: number,
  threshold: number
) => {
  hoverItemLast = hoverItem;

  if (response.length !== count) {
    response = new Array(count);
  }

  response.fill(0);

  hoverItem.forEach(({ index, distanceToRay }) => {
    if (index && distanceToRay) {
      response[index] = (threshold - distanceToRay) / threshold;
    }
  });

  if (obj) {
    obj.geometry.setAttribute(
      "aHoverScale",
      new Three.Float32BufferAttribute(response, 1)
    );
    // obj.geometry.attributes.aHoverScale.needsUpdate = true;
  }
};

const hoverEffect = ({
  obj,
  raycaster,
  width,
  camera,
  count,
  threshold,
  isTransition,
}: IProps) => {
  if (!obj || width < 1024 || isTransition) {
    if (!hoverItemLast.length) return;
    setHoverItem([], obj, count, threshold);
    return;
  }

  raycaster.setFromCamera(mousePosition, camera);

  const hoverItem = raycaster
    .intersectObject(obj)
    .map(({ index, distanceToRay }) => ({ index, distanceToRay }));

  if (!(!hoverItemLast.length && !hoverItem.length)) {
    setHoverItem(hoverItem, obj, count, threshold);
  }
};

export default hoverEffect;
