import { IData, IValueError } from "./types";

export const getValueError = <T>(value: T): IValueError<T> => ({
  value,
  error: "",
});

export const intiData: IData = {
  name: getValueError(""),
  email: getValueError(""),
  message: getValueError(""),
};

export const validateData = (data: IData) => {
  const arrayData = Object.entries(data) as [
    keyof IData,
    IValueError<string>
  ][];

  return arrayData.map(([key, { value }]) => {
    const reg = /\S+@\S+\.\S+/;
    if (!value.length) return { key, error: "Field is required" };
    if (key === "email" && !reg.test(value)) {
      return { key, error: "Email address is invalid" };
    }

    return { key, error: "" };
  });
};
