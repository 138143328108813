import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  code: ReactNode;
  nameError: ReactNode;
  text?: ReactNode;
}

const ErrorPage: FC<IProps> = ({ code, nameError, text }) => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperContent>
        <Styled.ErrorCode>{code}</Styled.ErrorCode>
        <Styled.ErrorName>{nameError}</Styled.ErrorName>
        <Styled.ErrorText>{text}</Styled.ErrorText>
      </Styled.WrapperContent>
    </Styled.Wrapper>
  );
};

export default ErrorPage;
