import styled from "styled-components";

export const Title = styled.div`
  font-size: 16px;
  color: white;
  margin-bottom: 4px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const Error = styled.div`
  font-size: 14px;
  color: #ea0b0b;
  margin-top: 4px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

interface IWrapperField {
  isFocus: boolean;
  isError: boolean;
}

const getBorder = (isFocus: boolean, isError: boolean, isHover: boolean) => {
  if (isError) return "#ea0b0b";
  if (isFocus || isHover) return "#53eaea";
  return "#ffffff";
};

export const WrapperField = styled.div<IWrapperField>`
  border: 2px solid;
  border-radius: 4px;
  padding: 0 10px;

  transition: border-color 0.5s;
  border-color: ${({ isFocus, isError }) => getBorder(isFocus, isError, false)};
  &:hover {
    border-color: ${({ isFocus, isError }) =>
      getBorder(isFocus, isError, true)};
  }
  & > input {
    height: 40px;
    width: 100%;
    color: white;
    font-size: 16px;
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    outline: unset;
  }
`;
