import { useMemo } from "react";
import * as Three from "three";
import { getColorPoints } from "../../../../../utils/getColor";

export interface IHoverItem {
  index?: number;
  distanceToRay?: number;
}

interface IProps {
  obj: Three.Points<
    Three.BufferGeometry,
    Three.Material | Three.Material[]
  > | null;
  count: number;
  countMove: number;
  randomScale: number;
  colorPoints?: Three.Color[];
}

const colorPointsInit = [
  new Three.Color(163 / 255, 228 / 255, 235 / 255),
  new Three.Color(21 / 255, 94 / 255, 105 / 255),
  new Three.Color(63 / 255, 170 / 255, 237 / 255),
  new Three.Color(156 / 255, 125 / 255, 231 / 255),
  new Three.Color(1, 1, 1),
];

const useGetAttribule = ({
  obj,
  count,
  countMove,
  randomScale,
  colorPoints = colorPointsInit,
}: IProps) => {
  const attribule1 = useMemo(() => {
    return {
      aColor: new Three.Float32BufferAttribute(
        getColorPoints(colorPoints, count),
        3
      ),
      aMove: new Three.Float32BufferAttribute(
        new Array(count)
          .fill(0)
          .map((_, index) => (!(index % countMove) ? 1 : 0)),
        1
      ),
    };
  }, [count, countMove]);

  const aRandom = useMemo(() => {
    const response = new Three.Float32BufferAttribute(
      new Array(count * 3)
        .fill(0)
        .map(() => (Math.random() - 0.5) * randomScale),
      3
    );

    if (obj) {
      obj.geometry.attributes.aRandom.needsUpdate = true;
    }

    return response;
  }, [count, countMove, randomScale]);



  return useMemo(
    () => ({
      ...attribule1,
      aHoverScale: new Three.Float32BufferAttribute(
        new Array(count).fill(0),
        1
      ),
      aRandom,
    }),
    [attribule1, aRandom]
  );
};

export default useGetAttribule;
