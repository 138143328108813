import * as Three from "three";
import { materialClouds } from ".";
import { dataScroll } from "../../../App";
import { getMinMax } from "../../../utils/getMinMax";
import { ISize } from "../../../utils/useWindowParams";
import { ISettingData } from "./types";

export const dataPointsSlide: ISettingData[] = [
  {
    position: new Three.Vector3(400, 0, -100),
    rotation: new Three.Euler(0, 0, 0.2),
  },
  {
    position: new Three.Vector3(400, -450, -200),
    rotation: new Three.Euler(0, 0, 0),
  },
  {
    position: new Three.Vector3(400, -450, -200),
    rotation: new Three.Euler(0, 0, 0),
  },
  {
    position: new Three.Vector3(400, -400, -250),
    rotation: new Three.Euler(0, 0, 0),
  },
  {
    position: new Three.Vector3(400, 50, -350),
    rotation: new Three.Euler(0, 0, 0),
  },
];

const getPosRotData = (
  index: number,
  index2: number,
  uTransition: number,
  key: keyof ISettingData
) => {
  const vec1 = dataPointsSlide[index][key];
  const vec2 = dataPointsSlide[index2][key];
  const res1 = new Three.Vector3(vec1.x, vec1.y, vec1.z);
  const res2 = new Three.Vector3(vec2.x, vec2.y, vec2.z);

  return res1.lerp(res2, uTransition);
};

export const getPosRot = ({ width, height }: ISize) => {
  const { uMode, uTransition, uSlide } = materialClouds;

  const index = uSlide - 1;
  const index2 =
    uMode || index === dataScroll.countSlides - 1 ? index - 1 : index + 1;
  const scaleWidth = getMinMax((width - 500) / 1420, 0, 1);
  // const scaleHeight = getMinMax(height / 1024 - 0.2, 0, 1);

  const position = getPosRotData(index, index2, uTransition, "position");
  const rotation = getPosRotData(index, index2, uTransition, "rotation");

  position.x *= scaleWidth;
  position.y *= materialClouds.uScaleHeight;

  return {
    position,
    rotation: new Three.Euler().setFromVector3(rotation),
  };
};
interface IPropsChangeValueByStep {
  value: number;
  percent: number;
  minStep?: number;
  maxValue: number;
}
export const changeValueByStep = ({
  value,
  percent,
  minStep,
  maxValue,
}: IPropsChangeValueByStep) => {
  const step = minStep ? (maxValue - value) * percent : maxValue * percent;

  const response = minStep && minStep < step ? value + step : maxValue;

  return response > maxValue ? maxValue : response;
};
