import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: unset;
  }
`;

export const Title = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 34px;
  font-size: 35px;
  /* font-weight: 600; */
  
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const WrapperData = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: unset;
  }
`;

export const TitleData = styled.div`
  margin-bottom: 16px;
  font-size: 22px;
  /* font-weight: 600; */
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
