import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  &:first-child > div:last-child {
    margin-left: unset;
  }
  & > div:last-child {
    margin-left: 15px;
  }

  @media (max-width: 1024px) {
    & > div:last-child {
      margin-left: unset;
    }
  }
`;
