import { FC, ReactNode, useId } from "react";


interface IProps {
  data: ReactNode[];
}

const MSTItem: FC<IProps> = ({ data }) => {
  const idComponent = useId();

  return (
    <div>
      {data.map((item, index) => (
        <div key={idComponent + "." + index}>{item}</div>
      ))}
    </div>
  );
};

export default MSTItem;
