import { FC, ReactNode } from 'react';

import WrapperNavFooter from '../wrappperNavFooter';
import { IPropsMenuComponent } from '../wrappperNavFooter/menu';
import * as Styled from './style';

interface IProps {
  children: ReactNode;
  propsMenu: IPropsMenuComponent;
}
const WrapperGlassPage: FC<IProps> = ({ children, propsMenu }) => {
  return (
    <WrapperNavFooter {...propsMenu}>
      <Styled.Wrapper>
        <Styled.WrapperContent>{children}</Styled.WrapperContent>
      </Styled.Wrapper>
    </WrapperNavFooter>
  );
};

export default WrapperGlassPage;
