import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 24px;
`;

export const WrapperTextLink = styled.div`
  display: inline;
  gap: 4px;
`;
