import { FC, ReactNode } from 'react';

import * as Styled from './style';

interface IPorpsButtonComponent {
  children: ReactNode;
  isLoading?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const ButtonComponent: FC<IPorpsButtonComponent> = ({
  children,
  isLoading = false,
  disable = false,
  onClick,
}) => {
  return ( 
    <Styled.Wrapper
      onClick={() => {
        if (!isLoading && !disable && onClick) onClick();
      }}
      isLoading={isLoading}
    >
      {children}
    </Styled.Wrapper>
  );
};

export default ButtonComponent;
