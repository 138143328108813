import styled from "styled-components";

interface IPoint {
  selected: boolean;
}

export const Point = styled.div<IPoint>`
  width: 24px;
  height: 24px;
  margin: 10px 0;
  background-image: ${({ selected }) =>
    selected ? "url(/disc.svg)" : "url(/point.svg)"};
  transform: translateY(-200%);
  cursor: pointer;
  &:last-child {
    margin-bottom: unset;
  }
`;
