import { FC, ReactNode, useId } from "react";
import * as Styled from "./style";

export interface IData {
  title?: string;
  data: ReactNode[];
}

export interface IPropsPolicyComponent {
  title: string;
  data: IData[];
}

const PolicyCompoponent: FC<IPropsPolicyComponent> = ({ title, data }) => {
  const idComponent = useId();
  return (
    <Styled.Wrapper>
      <Styled.Title>{title}</Styled.Title>
      {data.map(({ data, title },index) => {
        return (
          <Styled.WrapperData key={idComponent + ".1." + index}>
            {title && <Styled.TitleData>{title}</Styled.TitleData>}
            <Styled.Data>
              {data.map((item, index) => (
                <div key={idComponent + ".2." + index}>{item}</div>
              ))}
            </Styled.Data>
          </Styled.WrapperData>
        );
      })}
    </Styled.Wrapper>
  );
};

export default PolicyCompoponent;
