import * as Three from "three";
import { dataScroll } from "../../../../../App";
import { changeValueByStep } from "../../data";

interface IProps {
  obj: Three.Group | null;
  uTransition: number;

  changeSpeed: [number, number];
  rotationStatus: number;
  setRotationStatus: (value: number) => void;
}
let value = 0;

const transitionEffect = ({
  changeSpeed,
  obj,
  uTransition,
  rotationStatus,
  setRotationStatus,
}: IProps) => {

  const transition = changeValueByStep({
    value: uTransition,
    percent: !dataScroll.scrollSlide ? changeSpeed[0] : changeSpeed[1],
    minStep: 0.00001,
    maxValue: 1,
  });

  if (obj && transition < 0.8) {
    let valueChange = 0;
    if (rotationStatus > Math.PI) {
      valueChange = (2 * Math.PI - rotationStatus) * (transition / 0.8);
    } else {
      valueChange = -rotationStatus * (transition / 0.8);
    }
    value = rotationStatus + valueChange;
    obj.rotation.y = value;
  }
  if (obj && transition > 0.8 && transition < 0.95) {
    setRotationStatus(value);
  }
  // onChangeData("isBlock", true);
  return transition;
};

export default transitionEffect;
