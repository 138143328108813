import { FC, useId } from "react";
import LinkComponent, { IPropsLinkComponent } from "../../../../components/Link";
import * as Styled from "./style";

interface IProps {
  data: IPropsLinkComponent[];
}

const FooterProducts: FC<IProps> = ({ data }) => {
  const idComponent = useId();
  return (
    <Styled.Wrapper>
      <span> Products:</span>
      {data.map(({ children, ...propsLink }, index) => {
        return (
          <div key={idComponent + "." + index}>
            <LinkComponent {...propsLink}>{children}</LinkComponent>
            {index !== data.length - 1 && (
              <Styled.WrapperLine>|</Styled.WrapperLine>
            )}
          </div>
        );
      })}
    </Styled.Wrapper>
  );
};

export default FooterProducts;
