import { Children, FC, ReactNode, useId } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "./style";

interface IData {
  text: string;
  to: string;
}

export interface IPropsMenuComponent {
  data: IData[];
}

const MenuComponent: FC<IPropsMenuComponent> = ({ data }) => {
  const idComponent = useId();
  const navigate = useNavigate();
  return (
    <Styled.WrapperMenu>
      {data.map(({ text, to }, index) => (
        <Styled.Item
          key={idComponent + "." + index}
          onClick={() => navigate(to)}
        >
          {text}
        </Styled.Item>
      ))}
    </Styled.WrapperMenu>
  );
};

export default MenuComponent;
