import styled from "styled-components";

export const Wrapper = styled.div`
  width: 95%;
  height: 100vh;
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    &:last-child > div {
      transform: translateY(-15vh);
    }
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: white;
  font-size: 40px;
  font-family: sans-serif;
  transform: translateY(-5vh);
  & > div {
    word-wrap: break-word;
  }
  & > div:first-child {
    font-size: 80px;
    font-weight: 300;
    letter-spacing: -5px;
    line-height: 1;
  }

  & > div:last-child {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
  }

  @media (max-width: 1024px) {
    font-size: 30px;

    & > div:first-child {
      font-size: 60px;
    }
  }

  @media (max-width: 576px) {
    font-size: 20px;

    & > div:first-child {
      font-size: 40px;
    }
  }
`;
