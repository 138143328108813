import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 64px 0;
`;

interface IWrapperContent {
  isSubmit: boolean;
}

export const WrapperContent = styled.div`
  width: 80%;
  margin: 80px auto 0;
  color: white;
  position: relative;

  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  @media (max-width: 576px) {
    width: 95%;
    margin: 40px auto 0;
  }
`;

export const WrapperThanks = styled.div<IWrapperContent>`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 50px;
  opacity: ${({ isSubmit }) => (isSubmit ? 1 : 0)};
  transition: opacity 1s;
  z-index: ${({ isSubmit }) => (isSubmit ? 2 : -1)};
`;

export const WrapperForm = styled.div<IWrapperContent>`
  position: relative;
  top: 0;
  left: 0;

  width: 60%;
  margin: 64px auto;
  min-width: 600px;

  opacity: ${({ isSubmit }) => (isSubmit ? 0 : 1)};
  transition: opacity 0.1s;

  @media (max-width: 1024px) {
    min-width: unset;
    width: 80%;
  }

  @media (max-width: 576px) {
    width: 90%;
    margin: 16px auto;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 50px;
  /* font-weight: 600; */
  @media (max-width: 576px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.div`
  width: 70%;
  margin: 24px auto;
  font-size: 22px;
  text-align: center;
  margin-bottom: 60px;
  @media (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 24px;
  & > div {
    width: calc(50% - 20px);
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  left: calc(100% - 34px);
  height: 24px;
  width: 24px;
  cursor: pointer;
  z-index: 3;
  &:hover {
    & svg circle,
    & svg line {
      color: red;
    }
  }
`;

export const WrapperText = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* & > div:last-child:after {
    content: "";
  }
  & > div:after {
    content: "|";
  } */

  @media (max-width: 1024px) {
    justify-content: start;

    & > div:nth-child(2) {
      width: 100%;
    }
    /* & > div:first-child:after {
      content: "";
    }
    & > div:after {
      content: ",";
    } */
  }
`;
