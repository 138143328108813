import { CSSProperties, FC, ReactNode, useId } from "react";

interface IProps {
  title?: string;
  listStyleType?: CSSProperties["listStyleType"];
  data: ReactNode[];
}

const PolicyListComponent: FC<IProps> = ({ title, data, listStyleType }) => {
  const idComponent = useId();
  return (
    <div>
      {title && <div>{title}</div>}
      <ul style={{ listStyleType }}>
        {data.map((item, index) => (
          <li key={idComponent + "." + index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default PolicyListComponent;
