import { useLoader } from "@react-three/fiber";
import { useMemo } from "react";
import * as Three from "three";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader";

type TItem =
  | Three.BufferAttribute
  | Three.InterleavedBufferAttribute
  | Three.GLBufferAttribute
  | null;

type TKey = "aChain" | "aMan" | "aWoman" | "aEarth" | "aPass";

export type TDataGeometry = { [key in TKey]: TItem };

const getURL = (name: string, isMobile: boolean) =>
  `model/${isMobile ? "mobile" : "desktop"}/${name}`;

const getClouds = (names: string[], isMobile: boolean) => {
  return useLoader(
    PCDLoader,
    names.map((name) => getURL(name, isMobile))
  ).map((item) => item.geometry.getAttribute("position"));
};

export const useGetGeometry = (isMobile: boolean): TDataGeometry => {
  return useMemo(() => {
    const [aChain, aMan, aWoman, aEarth, aPass] = getClouds(
      [
        "chain_scaled.pcd",
        "men_scaled.pcd",
        "marina_scaled.pcd",
        "earth_scaled.pcd",
        "pass_scaled.pcd",
      ],
      isMobile
    );
    return {
      aChain,
      aMan,
      aWoman,
      aEarth,
      aPass,
    };
  }, []);
};
