import { useMemo } from "react";

import { IDataFrame } from "../../types";
import useRefClouds from "../useRefClouds";
import { IDataClounds } from "./types";

const dataFrame: IDataFrame = {
  transition: 0,
  rotation: 0,
  intervalOpacityID: 0,
  intervalTransitionID: 0,
  opacity: 0,
};

export const useDataClouds = (): IDataClounds => {
  const references = useRefClouds();

  return useMemo(
    () => ({
      ...references,
      dataFrame,
      isMobile: window.innerWidth < 768,
      threshold: 200,
      pointMesh: window.innerWidth < 768 ? [1, 3999] : [1, 8999],
    }),
    []
  );
};
