import { shaderMaterial } from "@react-three/drei";
import * as Three from "three";
import fragmentShader from "../shaders/clouds/fragment.glsl";
import vertexShader from "../shaders/clouds/vertex.glsl";

export interface IUFCloudPoints {
  uScaleHeight: number;
  uTime: number;
  uFrequency: Three.Vector2;
  uSlide: number;
  uTransition: number;
  uMode: boolean;
  uPixelRatio: number;
  uSize: number;
  uOpacity: number;
  uSizeMulti: number;
  uSizePlus: number;
  uSizeScale: boolean;
}

export const initUFCloudPoints: IUFCloudPoints = {
  uFrequency: new Three.Vector2(0.2, 0.2),
  uScaleHeight: 1,
  uTime: 0,
  uSlide: 1,
  uTransition: 0,
  uMode: false,
  uPixelRatio: Math.min(window.devicePixelRatio, 2),
  uSize: 1.3,
  uOpacity: 1,
  uSizeMulti: 5,
  uSizePlus: 3,
  uSizeScale: true,
};

export type TMaterialCloudsCreate = typeof Three.ShaderMaterial & IUFCloudPoints;
export type TMaterialClouds = Three.ShaderMaterial & IUFCloudPoints;

//@ts-ignore
export const MaterialClouds = shaderMaterial(
  { ...initUFCloudPoints },
  vertexShader,
  fragmentShader
) as TMaterialCloudsCreate;
