import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import { getTitle } from "./data";

const WrapperTitlePage = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title>{getTitle(pathname)}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default WrapperTitlePage;
