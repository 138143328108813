import { IPropsPolicyComponent } from '../../../components/policy';
import PolicyListComponent from '../../../components/policy/list';

export const dataCookieInfo: IPropsPolicyComponent[] = [
  {
    title: "Our Cookie Policy",
    data: [
      {
        data: [
          "Our website uses technology to collect information about its use, to distinguish between users, to help us provide you with a good experience and to allow us to improve the site and the service we offer. This means that when you visit our website, cookies or similar technologies will be placed on your computer or other device.",
        ],
      },
      {
        title: "What are cookies?",
        data: [
          "Cookies are small text files that are placed on your computer, smartphone or other device when you visit our website. A cookie file is stored on your device and allows us to recognize you and make your visit easier and more useful to you when you revisit our website.",
        ],
      },
      {
        title: "How does Chaindentity use cookies?",
        data: [
          "Cookies allow us to recognize users (where appropriate), tailor the content on our website to fit the needs of our website's visitors and helps us improve the user experience. Without certain types of cookies enabled, we can't guarantee that the website and your experience of it are as we intended it to be.",
          "We use cookies to obtain information about your visits and about the device you use to access our website.",
          <PolicyListComponent
            title="This includes but is not limited to, the following:"
            listStyleType="lower-alpha"
            data={[
              "the domain and host from which you access the Internet,",
              "the Internet Protocol address of the device or Internet Service Provider you are using,",
              "operating system in use (e.g. Macintosh, Windows),",
              "browser (e.g. Mozilla Firefox, Internet Explorer, Opera) and its version,",
              "the website which referred you to us,",
              "the type of the device you are using, and other similar information.",
            ]}
          />,
          "This allows us to determine your browsing habits, the content and pages that you access on our Websites, and the dates and times you visit the Websites, paths taken and time spent on sites and pages within the Websites.",
        ],
      },
      {
        title: "Types of cookies",
        data: [
          "The length of time a cookie stays on your device depends on its type. We use this type of cookies on our website:",
          "Session cookies are temporary cookies which only exist during the time you use the website (or more strictly, until you close the browser after using the website). Session cookies help our website remember what you chose on the previous page, avoiding the need to re-enter information and improve your experience whilst using the website.",
          "Personal data means data which identifies or can be used to identify you as an individual, including online identifiers such as your IP address. It also includes pseudonymous identifiers (e.g. strings of numbers or letters) if it is possible for us to identify you from these.",
        ],
      },
      {
        title: "Cookie settings",
        data: [
          "Once you choose to accept cookies in your browser’s settings, you agree to have Chaindentity use cookies on your device. If you do not accept cookies that are used on our websites you may block them by choosing a relevant option in your browser settings. Please be aware that if you choose not to accept our “cookies”, you may not be able to use all of the available features and we do not guarantee that our services and website will operate well.",
        ],
      },
    ],
  },
];
