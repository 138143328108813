import { useEffect, useState } from "react";

export interface ISize {
  width: number;
  height: number;
}

const getSize = (): ISize => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};


export const useWindowParams = () => {
  const [size, setSize] = useState(getSize());
  useEffect(() => {
    const handler = () => {
      setSize(getSize());
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);
  return size;
};
