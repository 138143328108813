import * as Three from "three";

export const getPostionWithScale = (
  value: Three.BufferAttribute,
  scale: number
) => {
  const responseValue = [];
  for (let i = 0; i < value.count; i++) {
    const [x, y, z] = [
      value.array[i * 3],
      value.array[i * 3 + 1],
      value.array[i * 3 + 2],
    ];
    responseValue.push(x * scale, y * scale, z * scale);
  }

  return new Three.Float32BufferAttribute(responseValue, 3);
};
