import { useId } from "react";
import PolicyCompoponent from "../../../components/policy";
import PolicyListComponent from "../../../components/policy/list";
import WrapperGlassPage from "../../../wrappers/wrapperGlassPage";
import { dataWebsitePrivacyInfo } from "./data";
import * as Styled from "./style";

const PolicyWebsitePrivacyPage = () => {
  const idComponent = useId();

  return (
    <WrapperGlassPage propsMenu={{ data: [{ text: "Home", to: "/" }] }}>
      <Styled.Wrapper>
        {dataWebsitePrivacyInfo.map((props, index) => (
          <PolicyCompoponent key={idComponent + "." + index} {...props} />
        ))}
      </Styled.Wrapper>
    </WrapperGlassPage>
  );
};

export default PolicyWebsitePrivacyPage;
