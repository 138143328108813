import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const WrapperSlider = styled.div`
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    transition: transform 1s;
  }
`;
const opacityAnimation = keyframes`
  0%{
    opacity:0;
  }
 
  100%{
    opacity:1;
  }
`;

interface IWrapperText {
  slide: number;
}

export const WrapperText = styled.div<IWrapperText>`
  width: 700px;
  height: 100vh;
  overflow: hidden;
  animation: 1.5s ${opacityAnimation} ease-in;
  & > div {
    position: relative;
    top: ${({ slide }) => `calc(-100% * ${slide})`};
    transition: top 1s;
  }
  & > div > div {
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  & > div > div:nth-child(${({ slide }) => slide + 1}) {
    opacity: 1;
  }
`;

interface IWrapperMoveLast {
  move?: number;
}

export const WrapperMoveLast = styled.div<IWrapperMoveLast>`
  width: fit-content;
  & > *:last-child {
    transform: ${({ move }) => `translateX(${move}px)`};
  }
`;
