import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 64px 0 128px;

  @media (max-width: 900px) {
    padding-bottom: 250px;
  }
`;

export const WrapperContent = styled.div`
  width: 80%;
  margin: 0 auto;
  color: white;
  position: relative;

  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  @media (max-width: 576px) {
    width: 95%;
    margin: 40px auto 0;
  }
`;
