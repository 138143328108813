import { FC, ReactNode } from "react";
import { useWindowParams } from "../../../utils/useWindowParams";
import * as Styled from "./style";

interface IProps {
  children?: ReactNode;
  mobileHide?: boolean;
}

const ContactPageTextItem: FC<IProps> = ({ children, mobileHide = false }) => {
  const { width } = useWindowParams();

  return (
    <Styled.Wrapper>
      <div>{children}</div>
      <div>{width > 1024 ? "|" : `${mobileHide ? "" : ","}`}</div>
    </Styled.Wrapper>
  );
};

export default ContactPageTextItem;
