import { useId } from 'react';

import PolicyCompoponent from '../../../components/policy';
import WrapperGlassPage from '../../../wrappers/wrapperGlassPage';
import { dataCookieInfo } from './data';
import * as Styled from './style';

const PolicyCookiePage = () => {
  const idComponent = useId();

  return (
    <WrapperGlassPage propsMenu={{ data: [{ text: "Home", to: "/" }] }}>
      <Styled.Wrapper>
        {dataCookieInfo.map((props, index) => (
          <PolicyCompoponent key={idComponent + "." + index} {...props} />
        ))}
      </Styled.Wrapper>
    </WrapperGlassPage>
  );
};

export default PolicyCookiePage;
