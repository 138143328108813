import { useId } from "react";
import PolicyCompoponent from "../../../components/policy";
import WrapperGlassPage from "../../../wrappers/wrapperGlassPage";
import { dataPrivacyInfo } from "./data";
import * as Styled from "./style";

const PolicyPrivacyPage = () => {
  const idComponent = useId();

  return (
    <WrapperGlassPage propsMenu={{ data: [{ text: "Home", to: "/" }] }}>
      <Styled.Wrapper>
        {dataPrivacyInfo.map((props,index) => (
          <PolicyCompoponent key={idComponent + "." + index} {...props} />
        ))}
      </Styled.Wrapper>
    </WrapperGlassPage>
  );
};

export default PolicyPrivacyPage;
