import { FC, useState } from "react";
import * as Styled from "./style";

export interface IPropsInputComponent {
  title?: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
  type?: string;
}

const InputComponent: FC<IPropsInputComponent> = ({
  title,
  value,
  error,
  onChange,
  type,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.WrapperField isFocus={isFocus} isError={!!error}>
        <input
          value={value}
          type={type}
          onChange={({ target }) => onChange(target.value)}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </Styled.WrapperField>
      {error && <Styled.Error>{error}</Styled.Error>}
    </div>
  );
};

export default InputComponent;
