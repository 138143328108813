import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
`;

export const ErrorCode = styled.div`
  font-size: 150px;
`;

export const ErrorName = styled.div`
  font-size: 24px;
  color: #b1b1b1;
`;

export const ErrorText = styled.div`
  margin-top: 24px;
`;
