import { FC, useEffect, useId } from 'react';

import { ISlideText } from '../types';
import MSTItem from './item';
import * as Styled from './style';

const MainSlideText: FC<ISlideText> = ({
  textContent,
  subTitle,
  title,
}) => {

  return (
    <Styled.Wrapper>
      <Styled.WrapperContent>
        {title ? <MSTItem data={title} /> : <div />}
        {subTitle ? <MSTItem data={subTitle} /> : <div />}
        {textContent ? <MSTItem data={textContent} /> : <div />}
      </Styled.WrapperContent>
    </Styled.Wrapper>
  );
};

export default MainSlideText;
