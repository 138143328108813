import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

const WrapperMetaRoutePolicies = () => {
  return (
    <>
      <Helmet>
       <meta name="robots" content="noindex"/>
      </Helmet>
      <Outlet />
    </>
  );
};

export default WrapperMetaRoutePolicies;
