import * as Three from "three";

export const getColorPoints= (colors: Three.Color[], count: number) => {
  const colorsResponse = new Array(count)
    .fill(1)
    .map(() => colors[Math.round(Math.random() * (colors.length - 1))]);
  const response: number[] = [];
  colorsResponse.forEach((item) => response.push(item.r, item.g, item.b));
  return response;
};

export const getColorRGB = (r: number, g: number, b: number) =>
  new Three.Color(r / 255, g / 255, b / 255);
