import { Link } from "react-router-dom";
import styled from "styled-components";
interface IWrapper {
  color: string;
  colorHover?: string;
  margin: boolean;
}
export const Wrapper = styled.div<IWrapper>`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: ${({ margin }) => (margin ? "0 4px" : "unset")};

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: #379cf5;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }

  & a {
    text-decoration: unset;
    color: ${({ color }) => color};
    transition: ${({ colorHover }) =>
      colorHover ? "color 0.6s cubic-bezier(0.25, 1, 0.5, 1)" : "unset"};
  }

  &:hover {
    &:before {
      width: 100%;
    }

    & a {
      color: ${({ color, colorHover }) => colorHover || color};
    }
  }
`;
