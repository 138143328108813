import styled from "styled-components";
interface IWrapper {
  show: boolean;
}
export const Wrapper = styled.div<IWrapper>`
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease-in;
  font-size: 14px;
  padding: 16px 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  /* transform: ${({ show }) =>
    show ? `translateY(0%)` : `translateY(100%)`}; */
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
  &:hover {
    color: #fff;
  }
  @media (max-width: 1200px) {
    color: #fff;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px 0;
  } ;
`;

export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: end;
  & > div {
    display: flex;
    justify-content: start;
  }
`;

export const WrapperSecondColumn = styled.div`
  display: flex;
  gap: 64px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    justify-content: end;

    @media (max-width: 768px) {
      align-items: flex-start;
      gap: 12px
    }
  }

  //& > div:first-child {
  //  align-items: flex-start;
  //}
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  margin-bottom: 4px;
  font-size: 15px;
  /* font-weight: bold; */
`;
