import { FC } from "react";
import { useNavigate, Link } from "react-router-dom";
// import { Link } from "../../commonStyle";
import { useWindowParams } from "../../../utils/useWindowParams";
import LinkComponent from "../../../components/Link";
import { dataPropducts } from "./data";
import FooterProducts from "./Products";
import * as Styled from "./style";
interface IProps {
  show: boolean;
}

const FooterComponent: FC<IProps> = ({ show }) => {

  return (
    <Styled.Wrapper show={show}>
      <Styled.WrapperContent>
        <Styled.WrapperColumn>
          <div>{`Copyright © ${new Date().getFullYear()} Chaindentity Ltd.`}</div>
          <FooterProducts data={dataPropducts} />
          <div>
            <span>A</span>
            <span>
              <LinkComponent
                to="https://unicamel.com/"
                target="_blank"
                color="inherit"
                webSite
                margin
              >
                UniCamel
              </LinkComponent>
            </span>
            <span> project.</span>
          </div>
        </Styled.WrapperColumn>
        <Styled.WrapperSecondColumn>
          <div>
            <LinkComponent
              to="/policy/cookie"
              target="_blank"
              color="inherit"
              rel="nofollow"
            >
              Cookie Policy
            </LinkComponent>
            <LinkComponent
              to="/policy/privacy"
              target="_blank"
              color="inherit"
              rel="nofollow"
            >
              Privacy Policy
            </LinkComponent>
            <LinkComponent
              to="/policy/websitePrivacy"
              target="_blank"
              color="inherit"
              rel="nofollow"
            >
              Website Privacy Policy
            </LinkComponent>
          </div>
        </Styled.WrapperSecondColumn>
      </Styled.WrapperContent>
    </Styled.Wrapper>
  );
};

export default FooterComponent;
