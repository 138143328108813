import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1320px;
  min-height: 100vh;
  position: relative;
  margin: 0 auto;
  @media (max-width: 1400px) {
    width: 1140px;
  }
  @media (max-width: 1200px) {
    width: 960px;
  }
  @media (max-width: 992px) {
    width: 720px;
  }
  @media (max-width: 768px) {
    width: 540px;
  }
  @media (max-width: 576px) {
    width: calc(100% - 40px);
  }
`;

export const WrapperChildren = styled.div`
  width: 100%;
  height: 100%;
`;
