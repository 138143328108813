import styled from "styled-components";
interface IWrapper {
  isLoading: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: fit-content;
  padding: 16px 32px;
  border-radius: 8px;
  background-color: rgb(47 177 196);
  cursor: ${({ isLoading }) => (isLoading ? "initial" : "pointer")};
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;
