import { useMemo, useRef } from "react";
import * as Three from "three";
import { MaterialClouds } from "../../../material/clouds";

const useRefClouds = () => {
  const refPositionGroup = useRef<Three.Group>(null);
  const refRotateGroup = useRef<Three.Group>(null);
  const refRotaionObjGroup = useRef<Three.Group>(null);
  const refPoints = useRef<Three.Points>(null);
  const materialRef = useRef<typeof MaterialClouds>(null);

  return useMemo(
    () => ({
      refPositionGroup,
      refRotateGroup,
      refRotaionObjGroup,
      refPoints,
      materialRef,
    }),
    [
      refPositionGroup,
      refRotateGroup,
      refRotaionObjGroup,
      refPoints,
      materialRef,
    ]
  );
};

export default useRefClouds;
