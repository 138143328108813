import { IPropsLinkComponent } from "../../../components/Link";

export const dataPropducts: IPropsLinkComponent[] = [
  {
    to: "#",
    target: "_blank",
    color: "inherit",
    rel: "nofollow",
    webSite: true,
    children: "LinkID",
  },
  {
    to: "https://netid.io",
    target: "_blank",
    color: "inherit",
    rel: "nofollow",
    webSite: true,
    children: "NetID",
  },
  {
    to: "#",
    target: "_blank",
    color: "inherit",
    rel: "nofollow",
    webSite: true,
    children: "AgeCTRL",
  },
];
