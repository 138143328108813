import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import * as Styled from "./style";

export interface IPropsLinkComponent extends LinkProps {
  color?: string;
  colorHover?: string;
  webSite?: boolean;
  margin?: boolean;
}

const LinkComponent: FC<IPropsLinkComponent> = ({
  children,
  color = "white",
  colorHover,
  webSite = false,
  margin = false,
  ...propsLink
}) => {
  return (
    <span>
      <Styled.Wrapper color={color} colorHover={colorHover} margin={margin}>
        {webSite ? (
          <a href={propsLink.to as string} target={propsLink.target}>
            {children}
          </a>
        ) : (
          <Link {...propsLink}>{children}</Link>
        )}
      </Styled.Wrapper>
    </span>
  );
};

export default LinkComponent;
