import { ISlideText } from "./types";
import * as Styled from "./style";


export const dataSlide = (width:number): ISlideText[] => {
  return [
    {
      title: [
        <Styled.WrapperMoveLast move={width > 576 ? -10 : -6}>
          <span>Chaindentity</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      subTitle: [
        <Styled.WrapperMoveLast move={width > 576 ? -5 : -2}>
          <span>On-chain, digital identity</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
    },
    {
      title: [
        <Styled.WrapperMoveLast move={width > 576 ? -10 : -6}>
          <span>On-chain</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      subTitle: [
        <Styled.WrapperMoveLast move={width > 576 ? -5 : -2}>
          <span>Decentralized, Tron</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      textContent: [
        "Chaindentity creates unique, digital identities from non-sensitive data, stored on the Tron blockchain. Chaindentity does not store any sensitive data.",
      ],
    },
    {
      title: [
        <Styled.WrapperMoveLast move={width > 576 ? -10 : -6}>
          <span>Verified</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      subTitle: [
        <Styled.WrapperMoveLast move={width > 576 ? -5 : -2}>
          <span>Staying anonymous</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      textContent: [
        "Chaindentity verifies users once, creates unique digital ID’s from non-sensitive data, stored on the Tron blockchain.",
      ],
    },
    {
      title: [
        <Styled.WrapperMoveLast move={width > 576 ? -10 : -6}>
          <span>Self governed:</span>
        </Styled.WrapperMoveLast>,
      ],
      subTitle: [
        <Styled.WrapperMoveLast move={width > 576 ? -5 : -2}>
          <span>Your data, your control</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      textContent: [
        "Personal data belongs to users, not third parties. Chaindentity gives control back to users.",
      ],
    },
    {
      title: [
        <Styled.WrapperMoveLast move={width > 576 ? -10 : -6}>
          <span>Chaindentity</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      subTitle: [
        <Styled.WrapperMoveLast move={width > 576 ? -5 : -2}>
          <span>Worldwide</span>
          <span>.</span>
        </Styled.WrapperMoveLast>,
      ],
      textContent: ["Decentralizing digital identities worldwide."],
    },
  ];
};
