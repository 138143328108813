import * as Three from "three";

interface IProps {
  obj: Three.Group | null;
  rotationStatus: number;
  speedRotation: number;
  setRotationStatus: (value: number) => void;
  isTransition: boolean;
}

const rotationEffect = ({
  obj,
  rotationStatus,
  setRotationStatus,
  speedRotation,
  isTransition,
}: IProps) => {
  if (!obj || isTransition) return;
  // obj.rotateY(speedRotation);
  let value = rotationStatus + speedRotation;
  if (value >= 2 * Math.PI) value -= 2 * Math.PI;
  setRotationStatus(value);
  obj.rotation.y = value;
};

export default rotationEffect;
