import LinkComponent from "../../../components/Link";
import { IPropsPolicyComponent } from "../../../components/policy";
import PolicyListComponent from "../../../components/policy/list";
import * as Styled from "./style";

export const dataWebsitePrivacyInfo: IPropsPolicyComponent[] = [
  {
    title: "Our Website Policy",
    data: [
      {
        title: "Your privacy",
        data: [
          <Styled.WrapperTextLink>
            <span>
              Your privacy is important to us. This policy (Privacy Policy)
              applies to personal information we collect from you when you use
              this site. If you’re a Chaindentity customer, or otherwise have a
              relationship with us, we may also have provided you with a
              separate privacy policy setting out how we use your personal
              information, which will also apply. You can also find that privacy
              policy by visiting the website for the product or services we
              provide you (
            </span>
            <LinkComponent color="#1c8cb8" to="/" target="_blank" margin>
              www.chaindentity.com
            </LinkComponent>
            <span>) or by contacting your customer service team.</span>
          </Styled.WrapperTextLink>,
          "Some of the links on this website may lead to or non-Chaindentity websites with their own privacy policies, which may be different to this policy, and you should read those policies carefully.",
          "You can also find our contact details towards the end of this policy.",
        ],
      },
      {
        title: "What are cookies?",
        data: [
          <PolicyListComponent
            title="We may collect and process the following information about you:"
            data={[
              "Information that you provide to us, for example when you fill out a contact or web form, or if you register to receive alerts or updates",
              "Information that we obtain or learn, such as information about the browser or device you use to access this site, how you use this site and the pages you visit, traffic and location data",
            ]}
          />,
          "We may also ask you for information if you experience problems when using this site.",
          "If we have an existing relationship with you, and we are able to identify you from information obtained or provided by your use of the site, we may associate those sets of information.",
        ],
      },
      {
        title: "How we use your information",
        data: [
          <PolicyListComponent
            title="We’ll only use your information where we have your consent or we have another lawful reason for using it. Unless we say otherwise below, we’ll use your personal information on the basis that it’s within our legitimate interests in operating and maintaining the site, and providing you with site functionality and related services. We use information provided or obtained via this site to:"
            data={[
              "respond to your queries (for example, if you’ve asked a question via the site)",
              "provide you with information, products or services you have requested or which we feel may interest you, unless you tell us otherwise",
              "carry out our obligations from any contracts entered into between you and us",
              "notify you about changes to the site",
              "ensure site content is presented in the most effective manner for the device you’re accessing it from",
              "detect and prevent misuse or abuse of this site or our services",
            ]}
          />,
          "We also use information to meet our compliance obligations, to comply with other laws and regulations and to share with regulators and other authorities that Chaindentity is subject to. This may include using it to help detect or prevent crime (including terrorism financing, money laundering and other financial crimes). We’ll only do this on the basis that it’s needed to comply with a legal obligation, it’s in our legitimate interests and that of others or to prevent or detect unlawful acts.",
        ],
      },
      {
        title: "Who we’ll share your information with",
        data: [
          "We may share your information in order to provide you with products or services you’ve requested, if we have a legitimate interest in doing so (for example, to manage risk, verify your identity, to combat fraud, abuse of our site or services), or where you’ve agreed to us doing so.",
          "We may share your information with others including any of our service providers, anyone else whose products and services you’ve requested, anyone who we’re under an obligation to disclose information to or where it’s in the public interest, (for example to prevent or detect fraud, abuse of our site or services).",
        ],
      },
      {
        title: "IP addresses",
        data: [
          "We may collect information about your computer (or mobile device), including where available your IP address, operating system and browser type, for system administration",
        ],
      },
      {
        title: "Cookies",
        data: [
          <Styled.WrapperTextLink>
            <span>
              We use cookies and similar technologies to distinguish you from
              other users of this site, to improve your experience when
              accessing this site, and to improve the site. Detailed information
              on the cookies we use and the purposes for which we use them are
              set out in our Cookie Policy at
            </span>
            <LinkComponent
              color="#1c8cb8"
              to="/policy/cookie"
              target="_blank"
              margin
            >
              www.chaindentity.com/policy/cookie.
            </LinkComponent>
          </Styled.WrapperTextLink>,
        ],
      },
      {
        title: "How long we’ll keep your information",
        data: [
          "Your personal and business information are stored as long as it is necessary to duly perform Chaindentity services to you and other obligations resulting from the binding agreements and as long as it is required by the applicable law.",
          "This enables us to comply with legal and regulatory requirements or use it where we need to for our legitimate purposes such as managing your account and dealing with any disputes or concerns that may arise.",
          "If we don’t need to retain information for any longer, we may destroy, delete or anonymise it more promptly.",
        ],
      },
      {
        title: "Your rights",
        data: [
          <PolicyListComponent
            title="You have a number of rights in relation to the information that we hold about you. These rights include:"
            data={[
              "the right to access information we hold about you and to obtain information about how we process it;",
              "in some circumstances, the right to withdraw your consent to our processing of your information, which you can do at any time. We may continue to process your information if we have another legitimate reason for doing so;",
              "in some circumstances, the right to receive certain information you have provided to us in an electronic format and/or request that we transmit it to a third party;",
              "the right to request that we rectify your information if it’s inaccurate or incomplete;",
              "in some circumstances, the right to request that we erase your information. We may continue to retain your information if we’re entitled or required to retain it;",
              "the right to object to, and to request that we restrict, our processing of your information in some circumstances. Again, there may be situations where you object to, or ask us to restrict, our processing of your information but we’re entitled to continue processing your information and/or to refuse that request.",
            ]}
          />,
          "You can exercise your rights by contacting us using the details set out in the ‘More details about your information’ section below. You also have a right to complain to the Information Commissioner’s Office or to the data protection regulator in the country where you live or work.",
        ],
      },
      {
        title: "How we keep your information secure",
        data: [
          "We have implemented a wide range of measures in order to ensure the security and confidentiality of your data. These include physical, electronic and administrative safeguards such as firewalls, data encryption, SSL and other up-to-date technologies.",
          "We enforce physical access controls to our buildings and files. In addition, only those employees who require personal information regarding our Customers to fulfill their employee’s obligations gain access to such information.",
          "We comply with applicable Payment Card Industry Data Security Standards (we obtained a PCI certificate) and endeavor to comply with all such rules at all times. Pursuant to such rules and regulations we are required to undergo periodic third-party assessments and periodic network scans to ensure that, among others, we have installed and maintain a firewall configuration to protect data; encrypt transmission of cardholder data and other sensitive information across public networks; do not use vendor-supplied defaults for system passwords and other security parameters; use and regularly update anti-virus software; develop and maintain secure systems and applications; restrict access to data to those with a business need-to-know; track and monitor all access to network resources and cardholder data; regularly test our security systems and processes; assign a unique ID to each person with computer access; restrict physical access to cardholder data.",
          "It is extremely important that you do not disclose your account password to anyone. Chaindentity has designed internal security processes that encrypt Customer’s password to protect it from being divulged or accessed by anyone other than you. Neither Chaindentity employees nor any of its contractors can obtain or access your password. Neither Chaindentity nor its contractors will ever ask you for your password via mail, email, telephone or in any other unsolicited manner. It is your responsibility to keep in secret your unique password and account information at all times.",
          "We cannot guarantee the security of your data while it is being transmitted over the Internet and through servers that are out of our control. We do our best to protect your personal information but we cannot ensure or warrant the security of any information you transmit to our website or services. Therefore, if you make any data transmissions over the Internet, you are doing it at your own risk. Once we receive the data transmission, we make our best efforts to ensure its security and privacy on our systems.",
        ],
      },
      {
        title: "More details about your information",
        data: [
          <Styled.WrapperTextLink>
            <span>
              If you have questions or concerns regarding this Privacy Policy,
              please do not hesitate to contact us either by using this link
            </span>
            <LinkComponent color="#1c8cb8" to="/contact" target="_blank" margin>
              www.chaindentity.com/contact
            </LinkComponent>
            <span>, an email:</span>
            <LinkComponent
              color="#1c8cb8"
              to="mailto:info@chaindentity.com"
              target="_blank"
              margin
            >
              info@chaindentity.com
            </LinkComponent>
            <span>or writing to us at:</span>
          </Styled.WrapperTextLink>,
          "Chaindentity LTD, address: North Row 64, London, United Kingdom",
          <Styled.WrapperTextLink>
            <span>
              This Privacy Policy may be updated from time to time and the most
              recent version can be found at
            </span>

            <LinkComponent
              color="#1c8cb8"
              to="/policy/privacy"
              target="_blank"
              margin
            >
              www.chaindentity.com/policy/privacy.
            </LinkComponent>
          </Styled.WrapperTextLink>,
          "This policy was last updated in February 2023",
        ],
      },
    ],
  },
];
