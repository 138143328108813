import { FC, useId } from "react";
import * as Styled from "./style";

interface IProps {
  count: number;
  setSlide: (value: number) => void;
  slide: number;
}

const SliderComponents: FC<IProps> = ({ slide, count, setSlide }) => {
  const idComponent = useId();
  return (
    <div>
      {new Array(count).fill(1).map((_, index) => (
        <Styled.Point
          key={idComponent + "." + index}
          onClick={() => {
            setSlide(index + 1);
          }}
          selected={slide === index}
        />
      ))}
    </div>
  );
};

export default SliderComponents;
