import { FC, useState } from "react";
import * as Styled from "./style";

export interface IPropsTextareaComponent {
  title?: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
  maxHeigth?: string;
  height?: string;
}

const TextareaComponent: FC<IPropsTextareaComponent> = ({
  title,
  value,
  error,
  onChange,
  maxHeigth = "120px",
  height,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Styled.Wrapper height={height}>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.WrapperField
        maxHeigth={maxHeigth}
        height={height}
        isFocus={isFocus}
        isError={!!error}
      >
        <textarea
          value={value}
          onChange={({ target }) => onChange(target.value)}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </Styled.WrapperField>
      {error && <Styled.Error>{error}</Styled.Error>}
    </Styled.Wrapper>
  );
};

export default TextareaComponent;
